<template>
  <DetailTemplate
    :customClass="'project-detail detail-page'"
    v-if="getPermission('project:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton"
            width="400"
            height="28"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
            {{ getPageTitle }}
          </h1>
          <CustomStatus
            :status.sync="projectArr.status"
            endpoint="project/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('project:update')">
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in projectMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        color="cyan"
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        v-on:click="pushToGantt()"
      >
        <v-icon small left>mdi-chart-gantt</v-icon>
        Gantt
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="projectTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 70vh; position: static"
      >
        <v-tabs-items v-model="projectTab">
          <v-tab-item v-if="getPermission('project:view')" :value="'project'">
            <OverviewCreate
              :projectArr.sync="projectArr"
              :project.sync="project"
              v-on:reload:project="getProject"
            ></OverviewCreate>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('pre-quotation:view')"
            value="pre-quotation"
          >
            <Quotations
              pre-quotation
              :detail.sync="projectArr"
              type="project"
            ></Quotations>
          </v-tab-item>
          <v-tab-item v-if="getPermission('quotation:view')" value="quotation">
            <Quotations :detail.sync="projectArr" type="project"></Quotations>
          </v-tab-item>
          <v-tab-item value="attachment">
            <RelatedDocument
              v-if="projectArr.id"
              :projectId="projectArr.id"
            ></RelatedDocument>
          </v-tab-item>
          <v-tab-item value="meeting">
            <Meeting
              type="project"
              :customer-name="customerName"
              :type-id="project"
              :detail.sync="projectArr"
            ></Meeting>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="task">
            <Tasks
              :detail.sync="projectArr"
              :customer-id="customerId()"
              type="project"
            ></Tasks>
          </v-tab-item>
          <v-tab-item
            value="contractor"
            v-if="getPermission('contractor:view')"
          >
            <Contractors :detail.sync="projectArr" type="project"></Contractors>
          </v-tab-item>
          <v-tab-item value="agreement" v-if="getPermission('agreement:view')">
            <Agreements :detail.sync="projectArr" type="project"></Agreements>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('purchase-order:view')"
            value="purchase-order"
          >
            <PurchaseOrder
              :detail.sync="projectArr"
              purchaseType="all"
              :projectId="project"
              type="project"
            ></PurchaseOrder>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <Invoices :detail.sync="projectArr" type="project"></Invoices>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="project"
              :type_id.sync="projectArr.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer> </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import OverviewCreate from "@/view/pages/project/partials/Overview-Create";
import Tasks from "@/view/pages/partials/Detail/Tasks";
// import Timesheets from "@/view/pages/partials/Detail/Timesheets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import PurchaseOrder from "@/view/pages/purchase-order/Purchase-Order-Listing-Template";
import Contractors from "@/view/pages/partials/Detail/Contractor";
// import ProjectCostings from "@/view/pages/partials/Detail/ProjectCosting";
import Agreements from "@/view/pages/partials/Detail/Agreements";
//import Invoices from "@/view/pages/partials/Detail/Invoice";0
import Invoices from "@/view/pages/partials/Invoice-Detail.vue";
import Meeting from "@/view/pages/partials/Detail/Meeting.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import RelatedDocument from "@/view/pages/partials/Related-Document.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "project-detail",
  title: "Project",
  data() {
    return {
      project: 0,
      pageLoading: true,
      customerName: null,
      projectArr: {},
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "project",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "pre-quotation",
          disabled: false,
        },
        {
          title: "Summary Report",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Documents",
          icon: "mdi-file-document",
          key: "attachment",
          disabled: false,
        },
        /*{
          title: "Contractors",
          icon: "mdi-account-multiple",
          key: "contractor",
          disabled: false,
        },*/
        /*{
          title: "Meetings",
          icon: "mdi-account-multiple",
          key: "meeting",
          disabled: false,
        },
        {
          title: "Tasks",
          icon: "mdi-credit-card",
          key: "task",
          disabled: false,
        },*/
        /*{
          title: "Purchase Order",
          icon: "mdi-account-multiple",
          key: "purchase-order",
          disabled: false,
        },*/
        /*{
          title: "Agreements",
          icon: "mdi-account-multiple",
          key: "agreement",
          disabled: false
        },*/
        /*{
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          disabled: false,
        },*/
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      projectMoreAction: [],
    };
  },
  components: {
    Tasks,
    Agreements,
    RelatedDocument,
    Invoices,
    PurchaseOrder,
    Quotations,
    Contractors,
    // Timesheets,
    DetailTemplate,
    OverviewCreate,
    CustomStatus,
    Meeting,
    // ProjectCostings,
    InternalHistoryDetail,
  },
  methods: {
    pushToGantt() {
      this.$router.push(
        this.getDefaultRoute("gantt.chart", {
          params: {
            project: this.projectArr.id,
          },
        })
      );
    },
    customerId() {
      if (this.projectArr.customer) {
        return this.projectArr.customer.customer;
      }
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("project.create", {
              query: {
                duplicate: _this.projectArr.id,
                customer: _this.lodash.toSafeInteger(
                  _this.projectArr.customer.customer
                ),
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("project.update", {
              params: {
                id: _this.projectArr.id,
              },
              query: {
                customer: _this.lodash.toSafeInteger(
                  _this.projectArr.customer.customer
                ),
              },
            })
          );
          break;
        case "mark_as_inprogress":
          _this.updateProjectStatus(2);
          break;
        case "mark_as_hold":
          _this.updateProjectStatus(3);
          break;
        case "mark_as_cancel":
          _this.updateProjectStatus(4);
          break;
        case "mark_as_finish":
          _this.updateProjectStatus(5);
          break;
        case "download_pdf":
          break;
        case "print":
          break;
      }
    },
    updateProjectStatus(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "project/" + _this.project + "/status",
          data: { status },
        })
        .then(() => {
          _this.getProject();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getProject() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "projects/" + _this.project,
        })
        .then(({ data }) => {
          _this.projectArr = data;
          _this.projectMoreAction = data.more_actions;
          _this.customerName = data.customer.display_name;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProject();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Project",
        route: "project",
      },
      {
        title: "Detail",
      },
    ]);
  },
  created() {
    const _this = this;
    _this.project = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.project || _this.project <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.projectTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.projectArr) === false) {
        if (this.projectArr.name) {
          result.push(this.projectArr.name);
        }
        if (this.projectArr.barcode) {
          result.push(this.projectArr.barcode);
        }
      }
      return result.join(" • ");
    },
    projectTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.projectTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "project";
      },
    },
  },
};
</script>
